// Switch component
// Value is 0 | 1
import { Box, SxProps, Typography } from "@mui/material";
export type SwitchValue = 0 | 1;
type SwitchProps = {
  left: string;
  right: string;
  value: SwitchValue;
  onChange: (value: SwitchValue) => any;
  sx?: SxProps;
};

function SwitchTab({
  label,
  selected,
  onClick,
}: {
  label: string;
  selected: boolean;
  onClick: any;
}) {
  return (
    <Box onClick={onClick} sx={{ width: "50%", padding: "2px" }}>
      <Typography
        sx={{
          textAlign: "center",
          backgroundColor: selected ? "primary.main" : undefined,
          color: "#FFF",
          padding: "4px 8px",
          borderRadius: "7px",
          fontSize: "13px",
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default function Switch({
  left,
  right,
  value,
  onChange,
  sx,
}: SwitchProps) {
  const handleClick = (newValue: SwitchValue) => {
    if (newValue === value) {
      return;
    }
    onChange(newValue);
  };

  return (
    <Box
      sx={{
        backgroundColor: "grey.200",
        borderRadius: "8px",
        overflow: "hidden",
        display: "flex",
        ...(sx || {}),
      }}
    >
      <SwitchTab
        label={left}
        selected={value === 0}
        onClick={() => handleClick(0)}
      />
      <SwitchTab
        label={right}
        selected={value === 1}
        onClick={() => handleClick(1)}
      />
    </Box>
  );
}
