import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button, MultiSelect, SelectOption } from "aagent-ui";
import { useState } from "react";
import * as types from "./types";

type MarkCompleteButtonProps = {
  disabled: boolean;
  handleComplete: (success: boolean) => any;
  planType: types.PlanType;
};

export default function MarkCompleteButton({
  disabled,
  handleComplete,
  planType,
}: MarkCompleteButtonProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const successOptions = [
    {
      name: "1",
      label: "Yes",
      selected: success,
    },
    {
      name: "0",
      label: "No",
      selected: !success,
    },
  ];

  return (
    <Box>
      <Button
        fullWidth
        sx={{ borderRadius: "10px" }}
        variant="outlined"
        color="success"
        disabled={disabled}
        onClick={() => setDialogOpen(true)}
      >
        Mark Complete
      </Button>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>
          Once the contract is marked complete, the contract will be reviewed by
          the merchant. Are you ready?
          {planType !== types.PlanType.GUEST && (
            <>
              <Box m="12px" />
              <MultiSelect
                options={successOptions}
                onSelect={() => setSuccess(!success)}
                label="Did you meet the social media influence criteria?"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "grey.200" }}
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "primary.dark" }}
            onClick={() => {
              setDialogOpen(false);
              handleComplete(success);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
