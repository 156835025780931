import { Box } from "@mui/material";
import { Button, toast } from "aagent-ui";
import { useRef, useState } from "react";
import S3Image from "./S3Image";

const FLIE_SIZE_LIMIT = 1024 * 1024 * 6; // 6 MB

type ImageUploaderProps = {
  onChange: (files: File[]) => any;
  initialImageS3Paths: string[];
};

export default function ImageUploader({
  onChange,
  initialImageS3Paths,
}: ImageUploaderProps) {
  const [srcs, setSrcs] = useState<string[]>([]);

  const handleChange = async (e: any) => {
    const files = Array.from(e.target.files as FileList);
    if (files.length === 0) return;

    // Check file size
    if (files.some((file) => file.size > FLIE_SIZE_LIMIT)) {
      toast.error(
        "Exceeds the file size limit (6Mb). Please use smaller images."
      );
      return;
    }

    // https://medium.com/@650egor/react-30-day-challenge-day-2-image-upload-preview-2d534f8eaaa
    setSrcs(files.map(URL.createObjectURL));

    // Private:
    // https://anonymous-agent-dev-receipt.s3.us-east-1.amazonaws.com/private/us-east-1%3A5f5db850-9f99-4774-92e2-f2b43d3af905/test.txt?x-id=PutObject
    // https://anonymous-agent-dev-receipt.s3.us-east-1.amazonaws.com/protected/us-east-1%3A5f5db850-9f99-4774-92e2-f2b43d3af905/test.txt?x-id=PutObject
    // https://anonymous-agent-dev-receipt.s3.us-east-1.amazonaws.com/public/test.txt?x-id=PutObject
    onChange(files);
  };
  const hiddenFileInput = useRef(null);
  const handleUpload = () => {
    if (!!hiddenFileInput && !!hiddenFileInput.current) {
      (hiddenFileInput.current as any).click();
    }
  };

  const imgSx = { width: "35px", height: "35px" };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ mr: "20px" }}>
        <Button
          variant="outlined"
          sx={{
            p: "6px 14px",
            fontSize: "12px",
            borderRadius: "10px",
          }}
          onClick={handleUpload}
        >
          UPLOAD IMAGE
        </Button>
        <input
          hidden
          ref={hiddenFileInput}
          accept="image/*"
          multiple
          type="file"
          onChange={handleChange}
        />
      </Box>

      {srcs.map((src: string, index: number) => (
        <Box component="img" src={src} key={index} sx={imgSx} />
      ))}

      {srcs.length === 0 &&
        initialImageS3Paths.map((path) => (
          <S3Image key={path} path={path} sx={imgSx} />
        ))}
    </Box>
  );
}
