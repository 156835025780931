import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import BottomNavigationLayout from "../BottomNavigationLayout";
import ContractCard from "../ContractCard";
import { isLatestCompleted } from "../statusUtils";
import Switch, { SwitchValue } from "../Switch";
import * as types from "../types";

export default function Contracts() {
  const [currentTab, setCurrentTab] = useState<SwitchValue>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [contracts, setContracts] = useState<types.Contract[]>([]);
  useEffect(() => {
    fetchAPI("/influencer/contracts")
      .then((response) => response.json())
      .then(setContracts)
      .then(() => setLoading(false))
      .catch((err) => toast.error(String(err)));
  }, []);
  const filteredContracts = contracts.filter((contract) => {
    return isLatestCompleted(contract.statuses) === !!currentTab;
  });

  return (
    <BottomNavigationLayout>
      <Container sx={{ padding: "50px 30px" }}>
        <Typography
          variant="overline"
          sx={{
            color: "primary.light",
            marginBottom: "6px",
            fontWeight: "bold",
          }}
        >
          WELCOME BACK
        </Typography>

        <Typography variant="h1">My Contracts</Typography>
        <Box margin="30px"></Box>

        <Switch
          left="CURRENT"
          right="COMPLETED"
          value={currentTab}
          onChange={setCurrentTab}
        />
        <Box margin="20px"></Box>

        {loading && <LinearProgress />}

        {/* All contracts */}
        <Box>
          {filteredContracts.map((contract) => (
            <ContractCard
              key={contract.id}
              post={contract.post as types.Post}
              linkTo={`/contract/${contract.id}`}
            />
          ))}
        </Box>
      </Container>
    </BottomNavigationLayout>
  );
}
