import { ReactNode } from "react";
import { Box, Container, Link, Typography } from "@mui/material";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import BottomNavigationLayout from "../BottomNavigationLayout";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Button } from "aagent-ui";

type FullWidthCardProps = {
  icon: any;
  children: ReactNode;
  to: string;
};

function FullWidthCard({ icon, children, to }: FullWidthCardProps) {
  return (
    <Link
      component={RouteLink}
      underline="none"
      to={to}
      sx={{
        background: "#FFFFFF",
        border: "1px solid rgba(238, 234, 248, 0.75)",
        boxShadow: "0px 5px 40px -11.4039px rgba(229, 233, 242, 0.3)",
        borderRadius: "30px",
        padding: "17px",

        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ marginRight: "28px", fontSize: "38px" }}>{icon}</Box>
      <Box sx={{ flex: 1 }}>{children}</Box>
      <ArrowForwardIosOutlinedIcon
        sx={{
          height: "20px",
          color: "primary.light",
        }}
      />
    </Link>
  );
}

export default function Settings({ signOut }: { signOut: any }) {
  const navigate = useNavigate();

  return (
    <BottomNavigationLayout sx={{ padding: "50px 14px" }}>
      <Container>
        <Box>
          <Typography variant="h1">Settings</Typography>
          <Box m="32px" />

          {/* Account */}
          <Box>
            <FullWidthCard icon={<span>&#128273;</span>} to="/settings/info">
              <Typography variant="h2">Account Information</Typography>
              <Typography variant="subtitle1">
                Edit your personal details
              </Typography>
            </FullWidthCard>

            <Box m="12px" />
            <FullWidthCard
              icon={<span>&#128179;</span>}
              to="/settings/bank-info"
            >
              <Typography variant="h2">Connect Bank</Typography>
              <Typography variant="subtitle1">
                Manage your compensation
              </Typography>
            </FullWidthCard>

            <Box m="30px" />
            <Button
              fullWidth
              onClick={() => {
                signOut();
                navigate("/");
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Container>
    </BottomNavigationLayout>
  );
}
