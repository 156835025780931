import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withAuthenticator } from "./authenticator_util";
import Overview from "./pages/Overview";
import { theme } from "aagent-ui";
import "./index.css";
import "aagent-ui/dist/index.css";
import Settings from "./pages/Settings";
import Contracts from "./pages/Contracts";
import PostDetail from "./pages/PostDetail";
import ContractGuide from "./pages/ContractGuide";
import AccountInfo from "./pages/AccountInfo";
import BankInfo from "./pages/BankInfo";

const INFLUENCER_USER_TYPE = "INFLUENCER";
const AuthenticatedRoutes = withAuthenticator(
  (props: any) => (
    <Routes>
      <Route path="/" element={<Overview />} />
      <Route path="/info" element={<AccountInfo {...props} />} />
      <Route path="/contracts" element={<Contracts {...props} />} />
      <Route
        path="/contract/:contractId"
        element={<ContractGuide {...props} />}
      />
      <Route path="/post/:postId" element={<PostDetail {...props} />} />
      <Route path="/settings" element={<Settings {...props} />} />
      <Route path="/settings/info" element={<AccountInfo {...props} />} />
      <Route path="/settings/bank-info" element={<BankInfo {...props} />} />
    </Routes>
  ),
  INFLUENCER_USER_TYPE
);

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthenticatedRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
