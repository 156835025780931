import { Box, Container, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavigationLayout from "./BottomNavigationLayout";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

type ImageCardBottomNavLayoutProps = {
  // Image displayed as the background of the image section
  imageUrl: string;

  // Content at the bottom of the image section
  imageBottom?: ReactNode;

  // Main content in the card
  children?: ReactNode;

  // If specified, back to this page, otherwise, just navigate to
  // previous page.
  backTo?: string;

  // Optional styles for the card component
  cardSx?: SxProps;
};

function ImageSection({
  url,
  bottom,
  backTo,
}: {
  url: string;
  bottom?: ReactNode;
  backTo?: string;
}) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "160px",
        backgroundImage: `url("${url}")`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        position: "relative",
      }}
    >
      {/* Semi-transparent cover */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background:
            "linear-gradient(190.62deg, rgba(44, 39, 69, 0.39) 7.98%, #3B3659 89.6%, #433E62 89.6%)",
        }}
      />

      <Container
        sx={{
          height: "100%",
          padding: "20px",
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "background.default",
              cursor: "pointer",
            }}
            onClick={() => (backTo ? navigate(backTo) : navigate(-1))}
          >
            <ArrowBackIosNewOutlinedIcon
              sx={{ fontSize: "16px", marginRight: "4px" }}
            />
            Back
          </Typography>
        </Box>
        <Box>{bottom}</Box>
      </Container>
    </Box>
  );
}

export default function ImageCardBottomNavLayout({
  imageUrl,
  imageBottom,
  children,
  backTo,
  cardSx,
}: ImageCardBottomNavLayoutProps) {
  return (
    <BottomNavigationLayout>
      {/* Image section at the top */}
      <ImageSection url={imageUrl} bottom={imageBottom} backTo={backTo} />

      <Container sx={{ py: { xs: "16px", sm: "32px" } }}>
        <Box
          sx={{
            background: "#FFFFFF",
            border: "1px solid rgba(238, 234, 248, 0.75)",
            boxShadow: "0px 0px 40px rgba(158, 160, 194, 0.15)",
            borderRadius: "20px",
            padding: "32px 24px",
            position: "relative",
            ...(cardSx || {}),
          }}
        >
          {children}
        </Box>
      </Container>
    </BottomNavigationLayout>
  );
}
