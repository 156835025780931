import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { ComponentType } from "react";
import { SvgIconProps } from "@mui/material";
export type { IconDefinition };
export type SvgIcon = ComponentType<SvgIconProps>;

export type UserInfo = any;

export type InfluencerInfo = {
  id: string;
  first_name: string;
  last_name: string;
  social_medias: SocialMediaPlatform[];
  review_platforms: ReviewPlatform[];
};

export enum ReviewPlatform {
  YELP = "YELP",
  GOOGLE_MAP = "GOOGLE_MAP",
}

export enum PlanType {
  GUEST = "GUEST",
  IMPACT = "IMPACT",
  FAME = "FAME",
  VIP = "VIP",
}

export enum BusinessType {
  RESTAURANT = "RESTAURANT",
  SPA = "SPA",
  SALON = "SALON",
  OTHER = "OTHER",
}

export type Address = {
  address_line1: string;
  address_line2?: string;
  city: string;
  state: string;
  zipcode: string;
};

export type Store = {
  business_name: string;
  business_type: BusinessType;
  price_range_per_person: number;
  address: Address;
};

export type Post = {
  id: string;
  plan_type: PlanType;
  store: Store;
  tiers: PlanTier[];
  max_reimbursement_per_bill: number;
};

export type Receipt = {
  image_s3_path: string;
};

export enum PlanTier {
  ENTRY = "ENTRY",
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM",
}

export enum SocialMediaPlatform {
  TIKTOK = "TIKTOK",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  YOUTUBE = "YOUTUBE",
  XIAO_HONG_SHU = "XIAO_HONG_SHU",
}

export type SocialMediaPost = {
  platform: SocialMediaPlatform;
  image_s3_paths: string[];
  last_updated: string;
};

export type Review = {
  platform: ReviewPlatform;
  image_s3_paths: string[];
  last_updated: string;
};

export type Contract = {
  id: string;
  created_timestamp: number;
  merchant_id: string;
  influencer_id: string;
  post: Post;
  reimbursement_amount?: number;
  subtotal?: number;
  receipts: Receipt[];
  social_medias: SocialMediaPost[];
  success?: boolean;
  reviews: Review[];
  statuses: Status[];
  charge_id?: string;
  // last_updated: datetime = Field(default_factory=datetime.now)
};

export enum StatusType {
  GIVE_UP = "GIVE_UP",
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
  WAIT_APPROVAL = "WAIT_APPROVAL",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export type Status = {
  status_type: StatusType;
  timestamp: string;
};

// https://stripe.com/docs/api/accounts/object#account_object-external_accounts
export type StripeExternalAccountData = {
  id: string;
  account: string;
  country: string;
  currency: string;
  last4: string;
  brand?: string;
  bank_name?: string;
};

// https://stripe.com/docs/api/accounts/object#account_object-external_accounts
export type StripeExternalAccount = {
  data: StripeExternalAccountData[];
};

// https://stripe.com/docs/api/accounts
export type StripeAccount = {
  id: string;
  email: string;
  country: string;
  business_type: string;
  charges_enabled: boolean;
  external_accounts: StripeExternalAccount[];
  requirements: {
    pending_verification: string[];
  };
};
