import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import BottomNavigationLayout from "../BottomNavigationLayout";
import CategoryBar from "../CategoryBar";
import ContractCard from "../ContractCard";
import * as types from "../types";

type OverviewViewProps = {
  posts: types.Post[];
  influencer: types.InfluencerInfo;
};

function OverviewView({ posts, influencer }: OverviewViewProps) {
  return (
    <BottomNavigationLayout sx={{ padding: "50px 14px" }}>
      <Container>
        <Box>
          <Typography
            variant="overline"
            sx={{ fontWeight: "bold", color: "primary.light" }}
          >
            WELCOME BACK
          </Typography>
          <Typography variant="h1">
            {influencer.first_name} {influencer.last_name}
          </Typography>

          <Box m="32px" />
          <CategoryBar />
          <Box m="32px" />
          <Box>
            {posts.map((post) => (
              <ContractCard
                key={post.id}
                post={post}
                linkTo={`/post/${post.id}`}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </BottomNavigationLayout>
  );
}

export default function Overview() {
  const [loading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<types.Post[]>([]);
  const [influencer, setInfluencer] = useState<types.InfluencerInfo>();

  useEffect(() => {
    fetchAPI("/posts")
      .then((response) => response.json())
      .then(setPosts)
      .then(() => setLoading(false))
      .catch((err) => toast.error(String(err)));
  }, []);

  useEffect(() => {
    fetchAPI("/influencer/info")
      .then((response) => response.json())
      .then(setInfluencer)
      .catch((err) => toast.error(String(err)));
  }, []);

  if (loading || !influencer) {
    return (
      <BottomNavigationLayout>
        <LinearProgress />
      </BottomNavigationLayout>
    );
  }

  return <OverviewView posts={posts} influencer={influencer} />;
}
