import { Box, Typography, Divider, LinearProgress } from "@mui/material";
import { Button, toast } from "aagent-ui";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAPI } from "../authenticator_util";
import ImageCardBottomNavLayout from "../ImageCardBottomNavLayout";
import ImageUploader from "../ImageUploader";
import MerchantDescription from "../MerchantDescription";
import { v4 as uuidv4 } from "uuid";
import * as types from "../types";
import AcceptedSocialMedias from "../AcceptedSocialMedias";
import AcceptedReviewPlatforms from "../AcceptedReviewPlatforms";
import * as statusUtils from "../statusUtils";
import { readyToComplete } from "../contractUtils";
import UpdateSubtotal from "../UpdateSubtotal";
import MarkCompleteButton from "../MarkCompleteButton";
import GiveupAction from "../GiveupAction";

const _StepTitle = ({
  overline,
  title,
}: {
  overline: string;
  title: string;
}) => (
  <Box>
    <Typography
      variant="h3"
      sx={{ fontSize: "13px", color: "primary.light" }}
      gutterBottom
    >
      {overline}
    </Typography>

    <Typography
      variant="h2"
      sx={{
        color: "secondary.main",
        fontWeight: 300,
        marginBottom: "12px",
      }}
    >
      {title}
    </Typography>
  </Box>
);

const uploadReceipts = async (contractId: string, files: File[]) => {
  if (files.length === 0) {
    return;
  }

  const filenames = files.map(
    (file) => `contract/${contractId}/receipt/${uuidv4()}-${file.name}`
  );
  const results = files.map((file, index) => {
    const filename = filenames[index];
    Storage.put(filename, file, {
      level: "public",
      progressCallback: (progress) =>
        console.log(`Uploaded: ${(100 * progress.loaded) / progress.total}%`),
    });
  });
  return Promise.all(results)
    .then(async (results) => {
      await fetchAPI(`/contract/${contractId}/update-receipts`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({ image_s3_paths: filenames }),
      });
      return toast.success(`Uploaded ${results.length} file(s)`);
    })
    .catch((err) => toast.error(String(err)));
};

const uploadSocialMedias = async (
  contractId: string,
  platform: types.SocialMediaPlatform,
  files: File[]
) => {
  if (files.length === 0) {
    return;
  }

  const filenames = files.map(
    (file) => `contract/${contractId}/social_media/${uuidv4()}-${file.name}`
  );
  const results = files.map((file, index) => {
    const filename = filenames[index];
    Storage.put(filename, file, {
      level: "public",
      progressCallback: (progress) =>
        console.log(`Uploaded: ${(100 * progress.loaded) / progress.total}%`),
    });
  });
  return Promise.all(results)
    .then(async (results) => {
      await fetchAPI(`/contract/${contractId}/update-social-medias`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          platform,
          image_s3_paths: filenames,
        }),
      });
      return toast.success(`Uploaded ${results.length} file(s)`);
    })
    .catch((err) => toast.error(String(err)));
};

const uploadReviews = (
  contractId: string,
  platform: types.ReviewPlatform,
  files: File[]
) => {
  if (files.length === 0) {
    return;
  }

  const filenames = files.map(
    (file) => `contract/${contractId}/review/${uuidv4()}-${file.name}`
  );
  const results = files.map((file, index) => {
    const filename = filenames[index];
    Storage.put(filename, file, {
      level: "public",
      progressCallback: (progress) =>
        console.log(`Uploaded: ${(100 * progress.loaded) / progress.total}%`),
    });
  });
  Promise.all(results)
    .then(async (results) => {
      await fetchAPI(`/contract/${contractId}/update-reviews`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          platform,
          image_s3_paths: filenames,
        }),
      });
      return toast.success(`Uploaded ${results.length} file(s)`);
    })
    .catch((err) => toast.error(String(err)));
};

export default function ContractGuide() {
  const { contractId } = useParams();
  const [contract, setContract] = useState<types.Contract>();
  const [socialMediaPlatform, setSocialMediaPlatform] =
    useState<types.SocialMediaPlatform>(types.SocialMediaPlatform.INSTAGRAM);
  const [reviewPlatform, setReviewPlatform] = useState<types.ReviewPlatform>(
    types.ReviewPlatform.GOOGLE_MAP
  );
  const [completed, setCompleted] = useState<boolean>(false);
  // Number of times that the contract is updated. Used to control fetch and re-render.
  const [contractUpdates, setContractUpdates] = useState<number>(0);

  useEffect(() => {
    fetchAPI(`/contract/${contractId}`)
      .then((response) => response.json())
      .then((contract) => {
        setContract(contract);
        if ((contract.social_medias || []).length > 0) {
          setSocialMediaPlatform(contract.social_medias[0].platform);
          setCompleted(statusUtils.isLatestCompleted(contract.statuses));
        }
      })
      .catch((err) => toast.error(String(err)));
  }, [contractId, completed, contractUpdates]);

  const imageUrl = "/shop_template.jpeg";
  if (!contractId || !contract) {
    return <LinearProgress />;
  }

  const handleComplete = (success: boolean) => {
    fetchAPI(`/contract/${contractId}/mark-complete`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({ success }),
    })
      .then(() => setCompleted(true))
      .then(() =>
        toast.success({
          title: "Congratulations!",
          text: "You have completed this task.",
        })
      )
      .catch((err) => toast.error(String(err)));
  };

  const imageBottom = (
    <Box>
      <Typography
        variant="overline"
        sx={{ fontWeight: "bold", color: "background.default" }}
      >
        {contract.post.store.business_type}
      </Typography>
      <Typography sx={{ color: "background.default" }} variant="h1">
        {contract.post.store.business_name}
      </Typography>
    </Box>
  );

  return (
    <ImageCardBottomNavLayout
      imageUrl={imageUrl}
      imageBottom={imageBottom}
      cardSx={{ padding: 0 }}
      backTo="/contracts"
    >
      {/* Step 1: visit store */}
      <Box sx={{ p: "24px", pt: "32px" }}>
        <_StepTitle overline="STEP ONE" title="Visit the merchant location" />
        <MerchantDescription store={contract.post.store} />
        <Box m="16px" />
      </Box>
      <Divider sx={{ borderColor: "secondary.light" }} />

      {/* Step 2: receipt */}
      <Box sx={{ p: "24px" }}>
        <_StepTitle overline="STEP TWO" title="Upload the receipt" />
        <Box m="24px" />
        <ImageUploader
          onChange={(files) =>
            uploadReceipts(contractId, files).then(() =>
              setContractUpdates(contractUpdates + 1)
            )
          }
          initialImageS3Paths={contract.receipts.map(
            (receipt) => receipt.image_s3_path
          )}
        />
        <Box m="24px" />
        {/* Only allow subtotal when receipt images are updated */}
        {contract.receipts.length > 0 && (
          <UpdateSubtotal contract={contract} setContract={setContract} />
        )}

        <Box m="16px" />
      </Box>
      <Divider sx={{ borderColor: "secondary.light" }} />

      {/* Step 3: review */}
      {/* <Box sx={{ p: "24px" }}>
        <_StepTitle overline="STEP THREE" title="Upload the review" />
        <AcceptedReviewPlatforms
          value={[reviewPlatform]}
          onChange={(platforms) => {
            if (platforms.length === 0) return;
            const newPlatform = platforms.filter(
              (p) => p !== reviewPlatform
            )[0];
            setReviewPlatform(newPlatform);
          }}
        />
        <Box m="24px" />
        <ImageUploader
          onChange={(files) => uploadReviews(contractId, reviewPlatform, files)}
          initialImageS3Paths={contract.reviews.flatMap(
            (review) => review.image_s3_paths
          )}
        />
        <Box m="16px" />
      </Box>
      <Divider sx={{ borderColor: "secondary.light" }} /> */}

      {/* Step 4: social media */}
      <Box sx={{ p: "24px", pb: "32px" }}>
        <_StepTitle
          overline="STEP THREE"
          title="Upload social media screenshots"
        />

        <AcceptedSocialMedias
          value={[socialMediaPlatform]}
          onChange={(newPlatforms) => {
            if (newPlatforms.length === 0) return;
            const newPlatform = newPlatforms.filter(
              (p) => p !== socialMediaPlatform
            )[0];
            setSocialMediaPlatform(newPlatform);
          }}
        />

        <Box m="24px" />
        <ImageUploader
          onChange={(files) =>
            uploadSocialMedias(contractId, socialMediaPlatform, files).then(
              () => setContractUpdates(contractUpdates + 1)
            )
          }
          initialImageS3Paths={contract.social_medias.flatMap(
            (social_media) => social_media.image_s3_paths
          )}
        />
        <Box m="24px" />

        {!completed && (
          <MarkCompleteButton
            disabled={!readyToComplete(contract)}
            handleComplete={handleComplete}
            planType={contract.post.plan_type}
          />
        )}

        {completed && (
          <Button
            fullWidth
            disableElevation
            sx={{ borderRadius: "10px" }}
            color="success"
          >
            Completed
          </Button>
        )}

        <Box m="8px" />
        {!completed && <GiveupAction contractId={contractId} />}
      </Box>
    </ImageCardBottomNavLayout>
  );
}
