import { Box, Typography } from "@mui/material";
import { Card } from "aagent-ui";
import { theme } from "aagent-ui";
import { Image as ImageIcon } from "akar-icons";
import { useNavigate } from "react-router-dom";
import * as types from "./types";

const PLAN_TYPE_TO_BANNER_COLOR = {
  [types.PlanType.GUEST]: "#B3D6F6",
  [types.PlanType.IMPACT]: "primary.light",
  [types.PlanType.FAME]: "#D8F3F3",
  [types.PlanType.VIP]: "#D8F3F3",
};

export default function ContractCard({
  post,
  linkTo,
}: {
  post: types.Post;
  linkTo?: string;
}) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        padding: "16px",
        borderRadius: "14px",
        background: "#FFF",
        marginBottom: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        overflow: "hidden",
        cursor: linkTo ? "pointer" : "inherit",
      }}
      onClick={() => linkTo && navigate(linkTo)}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "4px",
          backgroundColor: PLAN_TYPE_TO_BANNER_COLOR[post.plan_type],
        }}
      />

      <Box sx={{ marginRight: "12px" }}>
        <ImageIcon
          color={theme.palette.primary.dark}
          size={40}
          strokeWidth={1}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h3" sx={{ color: "primary.dark" }}>
          {post.plan_type}
        </Typography>
        <Typography
          variant="h2"
          sx={{ color: "primary.dark", marginBottom: "4px" }}
        >
          {post.store.business_name}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>
          {post.store.business_type}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="overline"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          {"$".repeat(post.store.price_range_per_person)}
        </Typography>
      </Box>
    </Card>
  );
}
