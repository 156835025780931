import { ComponentType, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Auth } from "aws-amplify";

export default function RequireInfo(Component: ComponentType) {
  const AppWithRequiredInfo = (props: any) => {
    const [user, setUser] = useState(props.user);

    const updateAttributes = async (attributes: any) => {
      await Auth.updateUserAttributes(user, attributes);
      const userNew = await Auth.currentAuthenticatedUser();
      setUser(userNew);
    };

    let profileComplete = user.attributes["custom:profile_complete"];
    profileComplete = !!profileComplete && JSON.parse(profileComplete);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (!profileComplete && location.pathname !== "/settings/info") {
        navigate("/settings/info", { replace: true });
      }
    }, [user.attributes, location.pathname]);

    if (!profileComplete && location.pathname !== "/settings/info") {
      return <div></div>;
    }

    return <Component {...props} updateAttributes={updateAttributes} />;
  };

  return AppWithRequiredInfo;
}
