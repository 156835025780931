import * as types from "../types";
import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import { Button, LoadingButton, toast } from "aagent-ui";
import BottomNavigationLayout from "../BottomNavigationLayout";

function BankInfoView({ account }: { account: types.StripeAccount }) {
  const [loadingStripe, setLoadingStripe] = useState<boolean>(false);

  const handleSetup = () => {
    setLoadingStripe(true);
    fetchAPI("/bank/account-link")
      .then((response) => response.json())
      .then((accountLink) => window.open(accountLink.url, "_blank"))
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoadingStripe(false));
  };

  const handleLogin = () => {
    setLoadingStripe(true);
    fetchAPI("/bank/account-stripe-login")
      .then((response) => response.json())
      .then((loginLink) => window.open(loginLink.url, "_blank"))
      .catch((err) => toast.error(String(err)))
      .finally(() => setLoadingStripe(false));
  };

  const needSetup =
    !account.charges_enabled &&
    account.requirements.pending_verification.length === 0;

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline" sx={{ color: "primary.light" }}>
        EDIT
      </Typography>
      <Typography variant="h1">Bank</Typography>
      <Box m="24px" />
      {needSetup && (
        <LoadingButton fullWidth loading={loadingStripe} onClick={handleSetup}>
          Set up stripe
        </LoadingButton>
      )}
      {!needSetup && (
        <LoadingButton fullWidth loading={loadingStripe} onClick={handleLogin}>
          Manage on Stripe
        </LoadingButton>
      )}
    </CardWithBottomNavLayout>
  );
}

export default function BankInfo() {
  const [account, setAccount] = useState<types.StripeAccount>();
  useEffect(() => {
    fetchAPI("/bank/account")
      .then((response) => response.json())
      .then(setAccount)
      .catch((err) => toast.error(String(err)));
  }, []);

  if (!account) {
    return (
      <BottomNavigationLayout>
        <LinearProgress />
      </BottomNavigationLayout>
    );
  }

  return <BankInfoView account={account} />;
}
