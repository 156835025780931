import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "aagent-ui";
import {
  faUtensils,
  faSpa,
  faMugSaucer,
  faScissors,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

function IconWithText({
  icon,
  text,
}: {
  icon: typeof faUtensils;
  text: string;
}) {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <FontAwesomeIcon
        icon={icon}
        color={theme.palette.primary.dark}
        style={{ fontSize: "17px", marginBottom: "8px" }}
      />
      <Typography sx={{ color: "secondary.main", fontSize: "11px" }}>
        {text}
      </Typography>
    </Box>
  );
}

export default function CategoryBar() {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
      <IconWithText icon={faUtensils} text={"Dining"} />
      <IconWithText icon={faSpa} text={"Spa"} />
      <IconWithText icon={faMugSaucer} text={"Cafe"} />
      <IconWithText icon={faScissors} text={"Salon"} />
      <IconWithText icon={faEllipsis} text={"More"} />
    </Box>
  );
}
