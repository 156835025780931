import * as types from "./types";
import { Box, Typography } from "@mui/material";
import { getFullAddress } from "./StoreUtils";

export default function MerchantDescription({ store }: { store: types.Store }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      {/* Address */}
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: "primary.dark",
            fontWeight: "bold",
            marginBottom: "4px",
          }}
        >
          ADDRESS
        </Typography>
        <Box
          component="a"
          href={`https://www.google.com/maps/search/${getFullAddress(store)}`}
          target="_blank"
        >
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {store.address.address_line1}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {store.address.city}, {store.address.state} {store.address.zipcode}
          </Typography>
        </Box>
      </Box>

      {/* Category */}
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: "primary.dark",
            fontWeight: "bold",
            marginBottom: "4px",
          }}
        >
          CATEGORY
        </Typography>
        <Box>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {store.business_type}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {"$".repeat(store.price_range_per_person)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
