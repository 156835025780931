import { Box, LinearProgress, Tooltip, Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { Button, LoadingButton, MultiSelect, toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAPI } from "../authenticator_util";
import ImageCardBottomNavLayout from "../ImageCardBottomNavLayout";
import MerchantDescription from "../MerchantDescription";
import SocialMediaRequirements from "../SocialMediaRequirements";
import * as types from "../types";

function getDueDateString() {
  const date = new Date();
  date.setDate(date.getDate() + 10);
  return date.toLocaleDateString();
}

function getCompensationText(post: types.Post, tier: types.PlanTier) {
  switch (post.plan_type) {
    case types.PlanType.GUEST:
      return `25% reimbursement (max \$${post.max_reimbursement_per_bill})`;
    case types.PlanType.IMPACT:
      const percent = {
        [types.PlanTier.ENTRY]: 30,
        [types.PlanTier.STANDARD]: 35,
        [types.PlanTier.PREMIUM]: 40,
      }[tier];
      const impactBonus = {
        [types.PlanTier.ENTRY]: 5,
        [types.PlanTier.STANDARD]: 15,
        [types.PlanTier.PREMIUM]: 30,
      }[tier];
      return `${percent}% reimbursement (max \$${post.max_reimbursement_per_bill}) + \$${impactBonus} bonus`;
    case types.PlanType.FAME:
      const fameBonus = {
        [types.PlanTier.ENTRY]: 15,
        [types.PlanTier.STANDARD]: 30,
        [types.PlanTier.PREMIUM]: 55,
      }[tier];
      return `\$${fameBonus} bonus`;
    default:
      return "";
  }
}

export default function PostDetail() {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<types.Post | undefined>(undefined);
  const [tier, setTier] = useState<types.PlanTier>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  useEffect(() => {
    fetchAPI(`/post/${postId}`)
      .then((response) => response.json())
      .then((post: types.Post) => {
        setPost(post);
        setTier(post.tiers[0]);
      });
  }, [postId]);

  if (post === undefined || tier === undefined) {
    return <LinearProgress />;
  }

  const createContract = () => {
    setIsSubmitting(true);
    fetchAPI(`/contract`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({ plan_id: postId, tier }),
    })
      .then((response) => response.json())
      .then((contract) => navigate(`/contract/${contract.id}`))
      .catch((err) => {
        toast.error(String(err));
        setIsSubmitting(false);
      });
  };

  // TODO: get image URL from backend
  const imageUrl = "/shop_template.jpeg";

  const imageBottom = (
    <Box>
      <Typography
        variant="overline"
        sx={{ fontWeight: "bold", color: "background.default" }}
      >
        {post.store.business_type}
      </Typography>
      <Typography sx={{ color: "background.default" }} variant="h1">
        {post.store.business_name}
      </Typography>
    </Box>
  );

  return (
    <ImageCardBottomNavLayout
      imageUrl={imageUrl}
      imageBottom={imageBottom}
      cardSx={{ padding: 0 }}
    >
      {/* Due date */}
      <Box
        sx={{
          padding: "32px 24px 16px 24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{ color: "primary.dark", marginBottom: "6px" }}
          >
            DUE DATE
          </Typography>
          <Typography variant="h2" sx={{ color: "secondary.main" }}>
            {getDueDateString()}
          </Typography>
        </Box>

        <Box>
          <LoadingButton
            sx={{ borderRadius: "10px", marginBottom: "6px", py: "8px" }}
            fullWidth
            color="success"
            onClick={createContract}
            loading={isSubmitting}
          >
            BEGIN TASK
          </LoadingButton>
          {/* <Typography
            sx={{ fontSize: "11px", fontWeight: "bold", color: "primary.dark" }}
          >
            TIME REMAINING: 12:22:39
          </Typography> */}
        </Box>
      </Box>
      <Divider sx={{ borderColor: "rgba(238, 234, 248, 0.75)" }} />

      {/* merchant info */}
      <Box sx={{ padding: "20px 24px" }}>
        <Typography
          variant="button"
          sx={{
            color: "secondary.main",
            marginBottom: "12px",
            display: "block",
            fontWeight: "bold",
          }}
        >
          VENDOR INFORMATION
        </Typography>

        <MerchantDescription store={post.store} />
      </Box>
      <Divider sx={{ borderColor: "rgba(238, 234, 248, 0.75)" }} />

      {/* Compensation */}
      <Box sx={{ padding: "20px 24px" }}>
        <Typography
          variant="button"
          sx={{
            color: "secondary.main",
            marginBottom: "12px",
            display: "block",
            fontWeight: "bold",
          }}
        >
          Terms
        </Typography>

        <MultiSelect
          options={post.tiers.map((t) => ({
            name: t,
            label: t,
            selected: t === tier,
          }))}
          onSelect={(tiers) => {
            tiers = tiers.filter((t) => t.name !== tier);
            // Need exactly one tier
            if (tiers.length === 0) return;
            setTier(tiers[0].name as types.PlanTier);
          }}
          label="Choose your tier"
        />

        <Box m="16px" />
        <Typography>Compensation</Typography>
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {getCompensationText(post, tier)}
        </Typography>

        <Box m="16px" />
        <Typography mb="4px">General Requirements</Typography>
        <Box>
          <Typography
            component="li"
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            Meet likes requirements
          </Typography>
          <Typography
            component="li"
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            Content @ and trackable to business
          </Typography>
          <Typography
            component="li"
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            50%+ contents about the vendor
          </Typography>
          <Typography
            component="li"
            variant="body2"
            sx={{ color: "text.secondary" }}
          >
            Authentic and positive
          </Typography>
        </Box>

        <Box m="16px" />
        <SocialMediaRequirements tier={tier} />
        <Box m="8px" />
      </Box>
    </ImageCardBottomNavLayout>
  );
}
