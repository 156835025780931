import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Button, LoadingButton, toast } from "aagent-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "./authenticator_util";

type GiveupActionProps = {
  contractId: string;
};

export default function GiveupAction({ contractId }: GiveupActionProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleGiveUp = () => {
    setLoading(true);
    fetchAPI(`/contract/${contractId}/give-up`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
      .then(() => {
        toast.success("You have given up this contract");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        toast.error(String(err));
        setLoading(false);
      });
  };

  return (
    <Box>
      <LoadingButton
        sx={{
          borderRadius: "10px",
          color: "text.secondary",
          borderColor: "grey.200",
        }}
        fullWidth
        variant="outlined"
        onClick={() => setDialogOpen(true)}
        loading={loading}
      >
        Give Up
      </LoadingButton>

      {/* Dialog for confirmation */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>
          Are you sure to give up the task? You will receive no compensation.
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "grey.200" }}
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "primary.dark" }}
            onClick={() => {
              setDialogOpen(false);
              handleGiveUp();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
