import * as types from "./types";
import * as yup from "yup";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button, LoadingButton, TextField, toast } from "aagent-ui";
import { useFormik } from "formik";
import { useState } from "react";
import { fetchAPI } from "./authenticator_util";

type UpdateSubtotalProps = {
  contract: types.Contract;
  setContract: any;
};

const validationSchema = yup.object({
  subtotal: yup
    .number()
    .required()
    .min(0, "The receipt subtotal must be a positive number")
    .test("maxDigits", "Please enter 2 digits or less", (number) =>
      /^\d+(\.\d{1,2})?$/.test(String(number))
    ),
});

export default function UpdateSubtotal({
  contract,
  setContract,
}: UpdateSubtotalProps) {
  const initialValues = { subtotal: contract.subtotal || 0.0 };
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const onSubmit = (rawValues: typeof initialValues) => {
    const values = validationSchema.cast(rawValues);
    setIsSubmitting(true);
    fetchAPI(`/contract/${contract.id}/update-subtotal`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then(setContract)
      .then(() => toast.success("Subtotal updated"))
      .catch((err) => toast.error(String(err)))
      .finally(() => setIsSubmitting(false));
  };

  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        name="subtotal"
        label="Subtotal"
        size="small"
        sx={{ maxWidth: "160px" }}
        type="number"
        value={values.subtotal}
        onChange={handleChange}
        error={touched.subtotal && Boolean(errors.subtotal)}
        helperText={touched.subtotal && errors.subtotal}
        disabled={!!contract.charge_id}
      />
      {(!contract.subtotal || contract.subtotal !== values.subtotal) &&
        !contract.charge_id && (
          <LoadingButton
            sx={{ p: "8px 16px", borderRadius: "20px" }}
            loading={isSubmitting}
            onClick={() => setDialogOpen(true)}
          >
            Update
          </LoadingButton>
        )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Please confirm</DialogTitle>
        <DialogContent>
          You entered the subtotal (pre-tax) amount to be ${values.subtotal}.
          Once set, the subtotal can NOT be changed. Are you sure this is the
          right amount?
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "grey.200" }}
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "primary.dark" }}
            onClick={() => {
              setDialogOpen(false);
              onSubmit(values);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
