import * as types from "./types";
import { getLatestStatus } from "./statusUtils";

export function readyToComplete(contract: types.Contract): boolean {
  if (
    getLatestStatus(contract.statuses) !== types.StatusType.WORK_IN_PROGRESS
  ) {
    // Already completed. No longer ready to complete
    return false;
  }

  // Ready to submit if it has the receipt, review and social media
  const hasReceipts = contract.receipts && contract.receipts.length > 0;
  // const hasReviews = contract.reviews && contract.reviews.length > 0;
  const hasSocialMedias =
    contract.social_medias && contract.social_medias.length > 0;
  return (
    hasReceipts &&
    hasSocialMedias &&
    !!contract.subtotal &&
    contract.subtotal >= 0
  );
}
