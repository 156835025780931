import CardWithBottomNavLayout from "../CardWithBottomNavLayout";
import * as yup from "yup";
import * as types from "../types";
import AcceptedSocialMedias from "../AcceptedSocialMedias";
import AcceptedReviewPlatforms from "../AcceptedReviewPlatforms";
import BottomNavigationLayout from "../BottomNavigationLayout";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton, TextField, toast } from "aagent-ui";
import { useEffect, useState } from "react";
import { fetchAPI } from "../authenticator_util";
import { useNavigate } from "react-router-dom";

const DEFAULT_VALUES = {
  first_name: "",
  last_name: "",
  social_medias: Object.values(types.SocialMediaPlatform),
  review_platforms: Object.values(types.ReviewPlatform),
};

const validationSchema = yup
  .object({
    first_name: yup
      .string()
      .trim()
      .required()
      .min(2, "First name is at least 2 letters long"),
    last_name: yup
      .string()
      .trim()
      .required()
      .min(2, "Last name is at least 2 letters long"),
    social_medias: yup
      .array()
      .of(
        yup
          .mixed<types.SocialMediaPlatform>()
          .oneOf(Object.values(types.SocialMediaPlatform))
          .defined()
      )
      .defined(),
    review_platforms: yup
      .array()
      .of(
        yup
          .mixed<types.ReviewPlatform>()
          .oneOf(Object.values(types.ReviewPlatform))
          .defined()
      )
      .defined(),
  })
  .defined();

type AccountInfoViewProps = {
  user: types.UserInfo;
  initialValues: typeof DEFAULT_VALUES;
  onSubmit: any;
  isSubmitting: boolean;
};

function AccountInfoView({
  user,
  initialValues,
  onSubmit,
  isSubmitting,
}: AccountInfoViewProps) {
  const { values, handleChange, handleSubmit, touched, errors, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <CardWithBottomNavLayout>
      <Typography variant="overline" sx={{ color: "primary.light" }}>
        EDIT
      </Typography>
      <Typography variant="h1">Account</Typography>
      <Box m="24px" />

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Email */}
        <TextField
          variant="outlined"
          fullWidth
          label="Email"
          size="small"
          margin="normal"
          value={user.attributes.email}
          disabled
        />
        {/* Phone number */}
        <TextField
          variant="outlined"
          fullWidth
          label="Phone Number"
          size="small"
          margin="normal"
          value={user.attributes.phone_number}
          disabled
        />

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* first name */}
          <TextField
            name="first_name"
            label="First Name"
            variant="outlined"
            size="small"
            sx={{ width: "48%" }}
            margin="normal"
            value={values.first_name}
            onChange={handleChange}
            error={touched.first_name && Boolean(errors.first_name)}
            helperText={touched.first_name && errors.first_name}
          />
          {/* Last name */}
          <TextField
            name="last_name"
            label="Last Name"
            variant="outlined"
            size="small"
            sx={{ width: "48%" }}
            margin="normal"
            value={values.last_name}
            onChange={handleChange}
            error={touched.last_name && Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
          />
        </Box>

        {/* Review Platform */}
        {/* <Box m="10px" />
        <Box>
          <Typography variant="h3" sx={{ textAlign: "center", mb: "12px" }}>
            REVIEW PLATFORM
          </Typography>
          <AcceptedReviewPlatforms
            value={values.review_platforms}
            onChange={(value) => setFieldValue("review_platforms", value)}
          />
        </Box> */}

        {/* Social Media */}
        <Box m="16px" />
        <Box>
          <Typography variant="h3" sx={{ textAlign: "center", mb: "12px" }}>
            SOCIAL MEDIA
          </Typography>
          <AcceptedSocialMedias
            value={values.social_medias}
            onChange={(value) => setFieldValue("social_medias", value)}
          />
        </Box>

        <Box m="18px" />
        <LoadingButton type="submit" fullWidth loading={isSubmitting}>
          Update
        </LoadingButton>
      </Box>
    </CardWithBottomNavLayout>
  );
}

export default function AccountInfo({
  user,
  updateAttributes,
}: {
  user: types.UserInfo;
  updateAttributes: any;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [influencerInfo, setInfluencerInfo] = useState<types.InfluencerInfo>();
  const navigate = useNavigate();

  const onSubmit = (rawValues: typeof DEFAULT_VALUES) => {
    setIsSubmitting(true);
    const values = validationSchema.cast(rawValues);
    fetchAPI("/influencer/info", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(() => {
        let profileComplete = user.attributes["custom:profile_complete"];
        profileComplete = !!profileComplete && JSON.parse(profileComplete);
        if (!profileComplete) {
          updateAttributes({
            "custom:profile_complete": "true",
          }).then(() => navigate("/"));
        } else {
          setIsSubmitting(false);
          toast.success("Profile updated");
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error(String(err));
      });
  };

  useEffect(() => {
    fetchAPI("/influencer/info")
      .then((response) => response.json())
      .then(setInfluencerInfo)
      .catch(() => setInfluencerInfo({ id: user.username, ...DEFAULT_VALUES }));
  }, [user]);

  if (!influencerInfo) {
    return (
      <BottomNavigationLayout>
        <LinearProgress />
      </BottomNavigationLayout>
    );
  }

  return (
    <AccountInfoView
      user={user}
      initialValues={influencerInfo}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    />
  );
}
