import * as types from "./types";
import { Box, Typography } from "@mui/material";

const LIKES_MAP = {
  [types.SocialMediaPlatform.FACEBOOK]: {
    [types.PlanTier.ENTRY]: 12,
    [types.PlanTier.STANDARD]: 30,
    [types.PlanTier.PREMIUM]: 50,
  },
  [types.SocialMediaPlatform.INSTAGRAM]: {
    [types.PlanTier.ENTRY]: 20,
    [types.PlanTier.STANDARD]: 50,
    [types.PlanTier.PREMIUM]: 80,
  },
  [types.SocialMediaPlatform.TIKTOK]: {
    [types.PlanTier.ENTRY]: 25,
    [types.PlanTier.STANDARD]: 65,
    [types.PlanTier.PREMIUM]: 110,
  },
  [types.SocialMediaPlatform.XIAO_HONG_SHU]: {
    [types.PlanTier.ENTRY]: 10,
    [types.PlanTier.STANDARD]: 30,
    [types.PlanTier.PREMIUM]: 60,
  },
};

export default function SocialMediaRequirements({
  tier,
}: {
  tier: types.PlanTier;
}) {
  return (
    <Box>
      <Typography mb="4px">Social Media Requirements</Typography>
      <Typography
        component="li"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        Facebook photo: {LIKES_MAP[types.SocialMediaPlatform.FACEBOOK][tier]}{" "}
        Likes
      </Typography>
      <Typography
        component="li"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        Instagram: {LIKES_MAP[types.SocialMediaPlatform.INSTAGRAM][tier]} Likes
        (1 collect = 1.5 likes)
      </Typography>
      <Typography
        component="li"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        TikTok: {LIKES_MAP[types.SocialMediaPlatform.TIKTOK][tier]} Likes (1
        share = 3 likes)
      </Typography>
      <Typography
        component="li"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        XiaoHongShu: {LIKES_MAP[types.SocialMediaPlatform.XIAO_HONG_SHU][tier]}{" "}
        Likes (1 collect = 2 likes)
      </Typography>
    </Box>
  );
}
